import React, { Component } from 'react'
import Layout from '../components/layout/layout'
import SEO from '../components/seo'
import CategoryType from '../components/category-type';
import Product from '../components/product';
import "../components/product.css"
import CategoryInformation from '../components/categoryInformation';
import { StaticQuery, graphql } from 'gatsby'
import MultiSelect from '../components/dropdown/multiselect';
import Banner from '../components/banner';
import SizeChart from '../components/size-chart';
import SeoText from '../components/browse-designs-seo-text';
import { BrowseDesignsSeoText, DiningRoomTags } from '../components/layout/strings';

const sizeFilters = [];
const themeFilters = [];
const isSizeOpen = false;
const isThemeOpen = false;
const sizePlaceholder = "";
const themePlaceholder = "" ;
const roomType = "";
const isSizeChartOpen = false;

class Lobby extends Component {

  
  constructor(props){
    super(props);
    this.state = {
      sizeFilters,
      themeFilters,
      isSizeOpen,
      isThemeOpen,
      sizePlaceholder,
      themePlaceholder,
      isSizeChartOpen
    };
  }

  componentDidMount(){
this.setState({
  sizeFilters : [ 
    {value: false, label: "Small", id: 1},
  {value: false, label: "Medium", id: 2},
  {value: false, label: "Large", id: 3},
],
themeFilters : [
  {value: false, label: "Contemporary", id: 1},
  {value: false, label: "Modern", id: 2},
  {value: false, label: "Rustic", id: 3},
  {value: false, label: "Antique", id: 4},
  {value: false, label: "Minimalist", id: 5},
  {value: false, label: "Traditional", id: 6},
  {value: false, label: "Blended", id: 7},
  ],
  isSizeOpen : false,
  isThemeOpen : false,
  sizePlaceholder : "Select Sizes",
  themePlaceholder : "Select Themes",
  isSizeChartOpen : false
});
  }

  handleSize = (sizeItems) => {
   this.setState({sizeFilters: sizeItems});
   this.setState({isSizeOpen: false});
   this.setState({isThemeOpen: false});
 }

 handleTheme = (listItems) => {
  this.setState({themeFilters: listItems});
  this.setState({isSizeOpen: false});
  this.setState({isThemeOpen: false});
}

handleSizeOpen = (isOpen) => {
  this.setState({isThemeOpen: false});
  this.setState({isSizeOpen: isOpen});
  console.log("hello");
}

handleThemeOpen = (isOpen) => {
  this.setState({isSizeOpen: false});
  this.setState({isThemeOpen: isOpen});
}

closeAll = () => {
  if(this.state.isSizeOpen || this.state.isThemeOpen){
    this.setState({isSizeOpen: false});
    this.setState({isThemeOpen: false});
  }
}

handleSizeChartClose = () => {
  this.setState({isSizeChartOpen : false});
}
  render(){

  return(
    <StaticQuery
    query={graphql`
    query {
      allMarkdownRemark (
      filter: { fileAbsolutePath: {regex : "\/lobby/"} },
        sort: {fields: [frontmatter___date], order: DESC},
      ){
        totalCount
        edges {
          node {
            ...Product
            fields {
                slug
            }
          }
        }
      }
      banner: file(relativePath: { eq: "banner_dining_room.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1980) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
    `}
    render={data => 
      <Layout
      selectedPage = "browseDesigns">
        <SEO 
          title={DiningRoomTags.title}
          description={DiningRoomTags.description} />
    <div onClick={() => this.closeAll(true)}>

    <div 
 style={{display: this.state.isSizeChartOpen ? 'block' : 'none'}}>

<SizeChart
handleClose={this.handleSizeChartClose}/>

 </div>
    <Banner 
        image={data.banner.childImageSharp.fluid}/>
        <div className="roomsAndFilterSelection">
        
        <CategoryType
        selectedCategory="Dining Room"/>
        
        <div className="roomsAndFilterSelectionRight">
        <div style={{
    paddingRight:'1.50vw',
    fontFamily:'Hind Guntur',
    fontSize:'calc(12px + (18 - 12) * ((100vw - 300px) / (1600 - 300)))'
        }}>Filters</div>
        
       <MultiSelect
            placeholder={this.state.sizePlaceholder}
            list={this.state.sizeFilters}
            onSelectItem={this.handleSize}
            onSelectBlock={this.handleSizeOpen}
            isOpen = {this.state.isSizeOpen}/>
           
           <div className="selectionBoxSpace"></div>
    <MultiSelect
           placeholder={this.state.themePlaceholder}
            list={this.state.themeFilters}
            onSelectItem={this.handleTheme}
            onSelectBlock={this.handleThemeOpen}
            isOpen = {this.state.isThemeOpen}/>

<p className="productSizeChartButton" onClick={() => this.setState({isSizeChartOpen : true})}> Size Chart</p>
        </div>
        </div>
        
        <div
        className="productOuterContainer"
        style={{
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center'
        }}
        
        >
    
    {data.allMarkdownRemark.edges.map(({ node, index}) => (
    
    <Product frontmatter={node.frontmatter}
    slug={node.fields.slug}
    sizeFilters = {this.state.sizeFilters}
    themeFilters = {this.state.themeFilters}
    id = {node.id}/>
    )
    )}
         
        </div>
        
        <SeoText data={BrowseDesignsSeoText.diningRoom}/>
        </div>
      </Layout>}
    />
  )
    }
  }


export default Lobby
